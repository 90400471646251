@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
$font-family: 'Inter', sans-serif;

$color-basic-100: #FFFFFF;
$color-basic-200: #EEF2FF;
$color-basic-300: #1BC300;
$color-basic-400: #FF0000;
$color-basic-500: #4BB7EA;
$color-basic-600: #2B5994;
$color-basic-700: #4D88DF;
$color-basic-800: #D4D4D4;
$color-basic-900: #000000;

$text-primary-hover-color: color-basic-600;

$font-family-primary: $font-family;
$font-family-secondary: $font-family;

$background-basic-color-1: color-basic-200;
$background-basic-color-2: color-basic-100;
$background-basic-color-3: color-basic-100;
$background-basic-color-4: color-basic-100;

$footer-background-color: color-basic-100;

$text-paragraph-font-size: 18px;
$user-medium-height: 32px;
$user-medium-width: 32px;
$text-paragraph-font-weight: 400;
$text-paragraph-font-weight-2: 700;
