@use '@angular/material' as mat;
@import "app/@theme/styles/variables";
@import "styles/mixins";


html, body { height: 100%; min-width: 375px; }
body { margin: 0; font-family: $font-family-primary; background: #020303; }
h1, h2, h3, h4, h5, h6{
  font-family: $font-family-primary !important;
  font-weight: 700 !important;
  margin: 0 !important;
}
table, p, a, span, li, button{
  font-family: $font-family-primary !important;
  margin: 0 !important;
}

// *{
//   outline: 4px solid green !important;
// }

::-webkit-scrollbar {
  width: 12px; /* Ширина полосы прокрутки */
}

/* Ползунок полосы прокрутки */
::-webkit-scrollbar-thumb {
  background-color: #303132;
  border-radius: 10px;
}

/* Полоса прокрутки при наведении */
::-webkit-scrollbar-thumb:hover {
  background-color: #272829;
}

/* Задний фон полосы прокрутки */
::-webkit-scrollbar-track {
  background: #1f1f20;
}

:root {
  @include mat.tooltip-overrides((
    container-color: #434343,
    supporting-text-color: #B3B3B3,
  ));
}

.mdc-tooltip.custom-tooltip {
  .mat-mdc-tooltip-surface {
    border: 1px solid #757575;
  }
}

.dropdown-list::-webkit-scrollbar {
  width: 4px;
}

.dropdown-list::-webkit-scrollbar-track {
  background: rgba(44, 44, 44, 1);
}

.dropdown-list::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 1);
  border-radius: 0;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Цвет ползунка при наведении */
}

.ng-star-inserted ngx-login{
  height: 100%;
}
nb-sidebar.menu-sidebar {
  display: none;
}

.disableSelection .ng-star-inserted{
  font-size: 18px;
  font-weight: 700;
  margin-right: 0.5rem;
}

.layout{
  align-items: center;

  nav.fixed{
    justify-content: center;
  }
}

.layout-container{
  width: 100%;
  padding-top: 0 !important;
}

nb-layout-header.fixed{
  background: #00000080 !important;
}

/* buttons */

.button-container {
  display: flex;
  gap: 20px;
}

.button {
  cursor: pointer;
  display: inline-block;
  padding: 10px 19.5px;
  text-decoration: none;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  text-align: center;
  position: relative;
  border-radius: 5px;
  border: 2px solid white; /* Граница по умолчанию */
}

.box.sign-in {
  --b: 1px;
  --s: 12px;
  --c: #D9D9D9;

  text-decoration: none;

  user-select: none;
  cursor: pointer;
  color: white !important;
  font-weight: 600;
  padding: 10px 19.5px;
  border: var(--b) solid var(--c);
  border-radius: 5px;
  clip-path: polygon(0 0, calc(100% - var(--s)) 0, 100% var(--s), 100% 100%, var(--s) 100%, 0 calc(100% - var(--s)));
  background: linear-gradient(-135deg, var(--c) calc(0.707* var(--s) + var(--b)), #0000 0) border-box, linear-gradient(45deg, var(--c) calc(0.707* var(--s) + var(--b)), #0000 0) border-box; background-color: transparent;

  font-size: 16px;
  line-height: 22.4px;
  position: relative;
}

.box.sign-in::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  transition: 0.3s;
  opacity: 0;
}

.box.sign-in:hover::before{
  opacity: 1;
  transition: 0.3s;
}

.box.sign-in:hover{
  color: black !important;
}

.box.sign-up {
  --b: 1px;
  --s: 12px;
  --c: #FFC107;

  text-decoration: none;

  user-select: none;
  cursor: pointer;
  padding: 10px 19.5px;
  color: #1E1E1E;
  font-weight: 600;
  border: var(--b) solid var(--c);
  border-radius: 5px;
  clip-path: polygon(0 0, calc(100% - var(--s)) 0, 100% var(--s), 100% 100%, var(--s) 100%, 0 calc(100% - var(--s)));
  background-color: #FFC107;

  font-size: 16px;
  line-height: 22.4px;
  position: relative;
  z-index: 1;
}

.box.sign-up::before{
  --s: 8px;

  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, calc(100% - var(--s)) 0, 100% var(--s), 100% 100%, var(--s) 100%, 0 calc(100% - var(--s)));
  background-color: white;
  z-index: -1;
  transition: 0.3s;
  opacity: 0;
}

.box.sign-up:hover::before {
  opacity: 1;
  transition: 0.3s;
}

.box.sign-up.new-padding {
  --s: 19px;
  padding: 12px 68px;
  font-size: 24px;
  line-height: 28.8px;
}

.box.sign-up.new-padding::before{
  --s: 15px;
}


.box-with-dots{
  --b: 2px;
  --s: 20px;
  --c: #D9D9D9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: white !important;
  font-weight: 600;
  padding: 12px 77.5px;
  border: var(--b) solid var(--c);
  border-radius: 5px;
  -webkit-clip-path: polygon(0 0, calc(100% - var(--s)) 0, 100% var(--s), 100% 100%, var(--s) 100%, 0 calc(100% - var(--s)));
  clip-path: polygon(0 0, calc(100% - var(--s)) 0, 100% var(--s), 100% 100%, var(--s) 100%, 0 calc(100% - var(--s)));
  background: linear-gradient(-135deg, var(--c) calc(0.707* var(--s) + var(--b)), rgba(0, 0, 0, 0) 0) border-box, linear-gradient(45deg, var(--c) calc(0.707* var(--s) + var(--b)), rgba(0, 0, 0, 0) 0) border-box;
  background-color: transparent;
  font-size: 24px;
  line-height: 28.8px;

  text-decoration: none;

  @include breakpoint-range($to: tablet){
    width: -webkit-fill-available;
    text-align: center;
  }

  position: relative;
  z-index: 1;
}

.help-dots{
  display: flex;
  position: relative;
  overflow: hidden;
}

.help-dots::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: #D9D9D9;
  width: 4px;
  height: 4px;
}
.help-dots::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #D9D9D9;
  width: 4px;
  height: 4px;
}

.box-with-dots:hover{
  --b: 3px;
  padding: 11px 77.5px;
}

.help-dots:hover::after{
  width: 14px;
  height: 14px;
  clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
  transition: 0.3s;
}

.help-dots:hover::before{
  width: 14px;
  height: 14px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  transition: 0.3s;
}


.scrollable-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  background: black;
}

.scrollable-container::-webkit-scrollbar-track {
  background: black; /* Color of the track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: black; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: black; /* Darker color when hovering */
}

.gradient-text {
  background: linear-gradient(270deg, #683500 10.47%, #E8B931 49.36%, #3784BC 84.1%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  letter-spacing: -3px !important;
}

/* app-profile */

app-profile{
  height: 100%;

  ngx-knowledge-base{
    height: 100%;
  }
}

/* knowledge-base */

.knowledge-base {

  .owl-stage{
    display: flex;
    padding: 0 60px;

    @include breakpoint-range($to: desktop) {
      padding: 0 20px;
    }
  }

  .owl-nav{
    margin: 40px 80px 0 0;
    display: flex;
    gap: 10px;
    justify-content: end;

    @include breakpoint-range($to: tablet){
      margin: 20px 27px 0 0;
    }

    .owl-prev {
      width: 44px;  /* Ширина кнопки */
      height: 44px; /* Высота кнопки */
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><path d="M34 22L11.5 22" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 14L10 22L19 30" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
      background-size: contain; /* Подгоняем размер SVG под кнопку */
      border: none;
      cursor: pointer;
    }

    .owl-next {
      width: 44px;  /* Ширина кнопки */
      height: 44px; /* Высота кнопки */
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><path d="M10 22L32.5 22" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 30L34 22L25 14" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
      background-size: contain;
      border: none;
      cursor: pointer;
    }

    .owl-prev:hover{
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><path d="M34 22L11.5 22" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 14L10 22L19 30" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
    }
    .owl-next:hover{
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><path d="M10 22L32.5 22" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 30L34 22L25 14" stroke="%23F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center center;
    }
  }
}

/* buttons */



.sign-in-icon{
  width: 32px;
  height: 32px;
}
.ng-trigger-accordionItemBody .item-body{
  padding-top: 0;
}

nb-accordion-item .expansion-indicator svg{
  display: none;
}

nb-accordion-item .expansion-indicator{
  display: flex;
  justify-content: center;
  width: 217px;
}

nb-accordion-item nb-accordion-item-header span{
  max-width: 1060px;
}

nb-accordion-item .expansion-indicator::before {
  content: "Read more";
  display: block;
  color: $color-basic-700;
  font-weight: 700;
  font-size: 16px;
}

nb-accordion-item .accordion-item-header-expanded .expansion-indicator::before {
  content: "Less";
  transform: rotate(180deg) !important;
}


tr.ng2-smart-row {
  cursor: pointer;
}

ng2-smart-table p{
  font-size: 16px;
  margin: 0;
}

.header-name span{
  font-weight: 400;
}

.col-referred-clients{
  text-align: start;
  font-size: 18px;
  border: none;
}
tr.ng-star-inserted{
  text-align: center;
}
tr.ng2-smart-row.ng-star-inserted{
  text-align: start;
}

.mat-tooltip.custom-tooltip {
  background: red !important;
}

@media screen and (max-width: 1023px){
  .layout-container{
    width: 100%;
  }

  ng2-smart-table p{
    font-size: 14px;
  }

  [dir=ltr] nb-accordion-item{
    nb-icon.expansion-indicator{
      position: absolute;
      top: 24px;
      right: 24px;
      display: inline-block;
      width: auto;
    }
    nb-accordion-item-header{
      padding-top: 64px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 767px){
  ngx-header{
    width: 100%;
    margin: 0 32px;
  }
}

@include breakpoint-range($from:tablet, $to: desktop){
  .term{

    .main-term{

      .terms{

        .usual-block{

          ul{
            padding-left: 24px !important;
          }

          ol{
            padding-left: 40px !important;

            li::before{
              left: -40px !important;
            }
          }

          h3{
            letter-spacing: normal !important;
            padding-left: 0 !important;
          }
        }

        .definitions-block{

          ul{
            padding-left: 24px !important;
          }
        }
      }
    }
  }
}

@include breakpoint-range($to: tablet) {
  .term{

    .main-term{

      .terms{

        .usual-block{

          ul{
            padding-left: 24px !important;
          }

          ol{
            padding-left: 40px !important;

            li::before{
              left: -40px !important;
            }
          }

          h3{
            letter-spacing: normal !important;
            padding-left: 0 !important;
          }
        }

        .definitions-block{

          ul{
            padding-left: 24px !important;
          }
        }
      }
    }
  }
}
